import React from 'react'
import styled from 'styled-components'

const Line = styled.div`
  width: 230px;
  height: 10px;
  border-top: solid 1px;
  border-bottom: solid 1px;
  border-color: ${props => (props.color ? props.color : props.theme.color)};
  margin: 15px auto;
`
const ParaLine = ({ children, color }) => <Line color={color}>{children}</Line>

export default ParaLine
