import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import bg from '../images/assets/fccMafia.jpg'
import communityJson from '../data/community.json'

const CommunitySection = styled.section.attrs({
  id: 'community',
})`
  background-color: var(--light);
`
const Container = styled.div`
    background-color: gray;
    background-image: url('${bg}');
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    `
const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5rem 0;
`
const Quote = styled.p`
  margin: 0 1rem;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  color: white;

  @media (min-width: 576px) {
    & {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.75rem;
    }
  }
`
const BadgeGroup = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 700px;
  margin: 0 auto;
  transform: translateY(-37px);

  @media (min-width: 576px) {
    & {
      transform: translateY(-42px);
    }
  }
  @media (min-width: 768px) {
    & {
      transform: translateY(-52px);
    }
  }
`
const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  border-radius: 50%;
  border: solid 5px var(--light);
  background-color: var(--light);
  transition: all 0.25s ease-in-out;

  &:hover {
    transform: translateY(-20px);
  }
`
const Badge = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: center center;

  @media (min-width: 576px) {
    & {
      width: 80px;
      height: 80px;
    }
  }
  @media (min-width: 768px) {
    & {
      width: 100px;
      height: 100px;
    }
  }
`

const Community = () => {
  const data = useStaticQuery(graphql`
    query {
      community: allFile(filter: { relativeDirectory: { eq: "community" } }) {
        nodes {
          childImageSharp {
            fixed(width: 200) {
              src
            }
          }
          base
        }
      }
    }
  `)

  const [community, setCommunity] = useState(null)
  useEffect(() => setCommunity(communityJson), [])

  const communityData = community
    ? community.map(group => {
        group.thumbnailSrc = data.community.nodes.find(
          img => group.thumbnail.toLowerCase() === img.base.toLowerCase()
        )
        return group
      })
    : []

  return (
    <CommunitySection>
      <Container>
        <Wrapper>
          <Quote>
            I'm proud I found this community who helped me grow professionally
          </Quote>
        </Wrapper>
      </Container>
      <BadgeGroup>
        {community &&
          communityData.map((group, index) => (
            <Link href={group.url} key={index}>
              <Badge
                src={group.thumbnailSrc.childImageSharp.fixed.src}
                alt={group.name}
                title={group.name}
              />
            </Link>
          ))}
      </BadgeGroup>
    </CommunitySection>
  )
}

export default Community
