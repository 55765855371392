import React from 'react'
import styled from 'styled-components'

import SectionHeader from './sectionHeader'

const AboutSection = styled.section.attrs({
  id: 'about',
})`
  padding: 5rem 0 3rem;
  background: var(--light);
`
const Container = styled.section.attrs({
  className: 'container',
})``
const Content = styled.main.attrs({
  className: 'pt-md-4 pt-sm-3 pt-2 px-3 pb-4',
})`
  margin: 0px auto;
  max-width: 1000px;
  text-align: center;
  color: var(--dark);
`
const Intro = styled.h4`
  font-size: 1.3rem;
  font-weight: 700;
  color: inherit;

  @media (min-width: 576px) {
    & {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.8rem;
    }
  }
`
const Para = styled.p.attrs({
  className: 'text-sm-center text-left',
})`
  font-size: 1rem;

  @media (min-width: 576px) {
    & {
      font-size: 1.1rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.2rem;
    }
  }
`
// const BottomBorder = styled.div`
//   border-bottom: solid 5px ${props => props.theme.color};
//   display: flex;
//   justify-content: center;
// `
// const CapsuleButton = styled.a.attrs({
//   target: '_blank',
//   rel: 'noopener noreferrer',
// })`
//   display: inline-block;
//   padding: 0.5rem 1.5rem;
//   border-radius: 2rem;
//   border: solid 3px ${props => props.theme.color};
//   background-color: var(--light);
//   color: ${props => props.theme.color};
//   font-size: 1.2rem;
//   text-decoration: none !important;
//   transform: translateY(25px);
//   transition: all 0.15s ease-in-out;

//   &:hover {
//     background-color: ${props => props.theme.color};
//     color: #fff;
//   }
// `
const About = () => {
  return (
    <AboutSection>
      <Container>
        <SectionHeader>
          About <span>me</span>
        </SectionHeader>
        <Content>
          <Intro>Hi, Nice to meet you.</Intro>
          <Para>
            After earning my bachelor degree in IT nearly{' '}
            {new Date().getFullYear() - 2015} years ago, I started my journey as
            a front-end developer here in the Philippines. I get myself learning
            new things everyday, enhancing my skills by developing simple fun
            web apps, and became a <strong>JavaScript</strong> and
            <strong> ReactJS</strong> Enthusiast.
          </Para>
          <Para>
            I'm open to work remotely on projects for clients all over the world
            whether in solo or together with a team. I'm naturally curious, and
            perpetually working on improving my chops one design problem at a
            time.
          </Para>
        </Content>
      </Container>
      {/* <BottomBorder>
        <CapsuleButton href='https://www.dropbox.com/s/fa6r3r28p3na3b5/Resume_MarkDino.pdf?dl=0'>
          Hire Me!
        </CapsuleButton>
      </BottomBorder> */}
    </AboutSection>
  )
}

export default About
