import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  text-transform: capitalize;

  span {
    color: ${props => props.theme.color};
  }

  @media (min-width: 576px) {
    & {
      font-size: 2.3rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 2.5rem;
    }
  }
`

const SectionHeader = ({ children, className }) => (
  <header className={className}>
    <Title>{children}</Title>
  </header>
)

export default SectionHeader
