import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import skillSet from '../data/skills.json'

import machine from '../svg/machine.svg'

const SkillsSection = styled.section.attrs({
  id: 'skills',
})`
  background-color: var(--light);
  padding-top: 3rem;
`

const SvgWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center',
})``
const Machine = styled.svg`
  max-width: 860px;
  height: 100%;

  .color-stroke {
    stroke: ${props => props.theme.color};
  }
  .color-fill {
    fill: ${props => props.theme.color};
  }
  .background-fill {
    fill: var(--light) !important;
  }
`
const Container = styled.div`
  padding: 3rem 0;
  background-color: ${props => props.theme.rgba(0.3)};
  min-height: 300px;
`
const HeaderTitle = styled.h3`
  color: #fff;
  font-size: 2.8rem;
  font-weight: 700;
  color: var(--dark);
  text-align: center;
`
const HeaderSub = styled.p`
  font-weight: 900;
  font-size: 1.3rem;
  text-align: center;
  margin: 0;
  color: #696969;
`
const SkillSet = styled.div.attrs({
  className: 'row my-0 mx-auto justify-content-around align-items-center',
  id: 'skillset',
})`
  max-width: 1546px;
`
const AOS_ZOOM_IN = styled.div.attrs(({ delay }) => ({
  'data-aos': 'zoom-in',
  'data-aos-delay': 100 * delay,
  'data-aos-once': true,
}))``
const Skill = styled.div.attrs({
  className: 'mx-1 mx-sm-2 mx-md-3 mx-lg-3',
})`
  width: 110px;
  height: 110px;
  transform: scale(0.65);
  transition: transform 0.5s;

  &:hover {
    transform: scale(1);

    p {
      opacity: 1;
    }
  }

  @media (min-width: 576px) {
    & {
      width: 120px;
      height: 120px;
    }
  }
  @media (min-width: 768px) {
    & {
      width: 130px;
      height: 130px;
    }
  }
  @media (min-width: 992px) {
    & {
      width: 140px;
      height: 140px;
    }
  }
`
const SkillTitle = styled.p`
  text-align: center;
  white-space: nowrap;
  font-weight: 700;
  font-size: 0.9rem;
  margin: 0px;
  transition: opacity 0.5s;
  opacity: 0;

  @media (min-width: 576px) {
    & {
      font-size: 1rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.2rem;
    }
  }
`
const Stacks = () => {
  const [skillsetWidth, setSkillsetWidth] = useState(1546)
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "skills" } }) {
        nodes {
          childImageSharp {
            fluid(maxHeight: 100) {
              ...GatsbyImageSharpFluid
            }
            id
          }
          name
        }
      }
    }
  `)

  const skills = skillSet.map(skill => {
    skill.thumbnail = data.allFile.nodes.find(img =>
      skill.name.toLocaleLowerCase().includes(img.name.toLocaleLowerCase())
    )
    return skill
  })

  useEffect(() => {
    if (document) {
      setSkillsetWidth(document.querySelector('#skillset').clientWidth)
    }
  }, [])
  return (
    <SkillsSection>
      <SvgWrapper>
        <Machine as={machine} />
      </SvgWrapper>
      <Container>
        <HeaderTitle>Skills</HeaderTitle>
        <HeaderSub>TECH STACKS</HeaderSub>
        <SkillSet>
          {skills.map((skill, index) => (
            <AOS_ZOOM_IN delay={skillsetWidth >= 576 ? index : 0} key={index}>
              <Skill key={skill.thumbnail.childImageSharp.id} index={index}>
                <Image
                  fluid={skill.thumbnail.childImageSharp.fluid}
                  alt={skill.name}
                />
                <SkillTitle>{skill.name}</SkillTitle>
              </Skill>
            </AOS_ZOOM_IN>
          ))}
        </SkillSet>
      </Container>
    </SkillsSection>
  )
}

export default Stacks
