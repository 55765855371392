import React from 'react'
import styled from 'styled-components'

const OpenSource = styled.div.attrs(({ reverse }) => ({
  className: `mb-5 row ${reverse ? 'flex-md-row-reverse' : ''}`,
}))``

const ThumbnailWrapper = styled.div.attrs(({ fadeRight }) => ({
  className: 'col col-lg-5 col-md-6 col-12 my-2',
  'data-aos': fadeRight ? 'fade-right' : 'fade-left',
  'data-aos-duration': 500,
  'data-aos-once': true,
}))``
const Img = styled.img`
  width: 100%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
`
const ContentWrapper = styled.div.attrs({
  className: 'col col-lg-7 col-md-6 col-12 my-2',
})``
const ContentTitle = styled.h5`
  margin-bottom: 1rem;
  font-weight: bold;
`
const Desc = styled.p`
  color: #404040;
  font-size: 1.1rem;
`
const Capsule = styled.div`
  display: inline-block;
  margin: 5px;
  padding: 0px;
  font-size: 0.8rem;
`
const PrId = styled.div`
  display: inline-block;
  padding: 0.15rem 0.15rem 0.15rem 0.55rem;
  background-color: ${props => props.theme.color};
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border: solid 1px ${props => props.theme.color};
  color: #fff;
`
const PrLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: inline-block;
  padding: 0.15rem 0.55rem 0.15rem 0.15rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: solid 1px ${props => props.theme.color};
  text-decoration: none !important;
  font-weight: 600;
  color: ${props => props.theme.color};
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.color};
    color: #fff;
  }
`

const OpenSourceCard = ({ thumbnailSrc, name, desc, repo, pr }) => {
  return (
    <OpenSource>
      <ThumbnailWrapper fadeRight={true}>
        <Img src={thumbnailSrc.childImageSharp.fluid.src} alt={name} />
      </ThumbnailWrapper>
      <ContentWrapper>
        <ContentTitle>{name}</ContentTitle>
        <Desc>{desc}</Desc>
        {pr.map(pull => (
          <Capsule key={pull.id}>
            <PrId>PR#{pull.id}</PrId>
            <PrLink href={`${repo}/pull/${pull.id}`}>{pull.name}</PrLink>
          </Capsule>
        ))}
      </ContentWrapper>
    </OpenSource>
  )
}

export default OpenSourceCard
