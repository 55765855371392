import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import ParaLine from './paraLine'

import Linkedin from '../svg/linkedin-in.svg'
import Facebook from '../svg/facebook-f.svg'
import GitHub from '../svg/github.svg'
import FreeCodeCamp from '../svg/free-code-camp.svg'
import Mail from '../svg/envelope.svg'
import Heart from '../svg/heart.svg'
import CopyRight from '../svg/copyright.svg'
import Gatsby from '../svg/gatsby.svg'

const FooterSection = styled.section.attrs({
  id: 'footer',
})`
  padding: 8rem 0 3rem;
  background-color: var(--dark);
`

const Container = styled.div.attrs({
  className: 'container d-flex flex-column align-items-center',
})``

const Avatar = styled.img.attrs({
  alt: 'Mark Dino Pelonia',
  className: 'mt-4 mx-auto',
})`
  max-width: 100px;
`
const Author = styled.h4.attrs({
  className: 'text-light m-0 mt-2',
})`
  font-weight: 700;
`
const Quote = styled.p`
  color: #fff;
  text-align: center;
  max-width: 400px;
  margin: 2rem auto 0;
  font-size: 1.1rem;
  opacity: 0.7;

  @media (min-width: 576px) {
    & {
      font-size: 1.3rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.4rem;
    }
  }
`
const BadgeGroup = styled.div.attrs({
  className: 'row my-5 justify-content-center',
})``

const BadgeLink = styled.a.attrs(({ delay }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  className:
    'p-2 m-md-3 m-2 rounded-circle d-flex align-items-center justify-content-center',
  'data-aos': 'flip-left',
  'data-aos-delay': delay * 100,
  'data-aos-once': true,
}))`
  border: solid 2px rgba(255, 255, 255, 0.3);
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in-out;

  svg {
    height: 20px;
    fill: #fff;
  }

  &:hover {
    background-color: #fff;
    border-color: #fff;

    svg {
      fill: var(--dark);
    }
  }
`
const CopyRightText = styled.p`
  color: #fff;
  opacity: 0.7;
  text-align: center;
  font-weight: 300;

  svg {
    opacity: 0.7;
    fill: #fff;
    height: 1rem;
  }
`
const Made = styled.p`
  display: flex;
  align-items: center;
  color: #fff;
  opacity: 0.5;
  text-align: center;
  font-size: 0.7rem;
  margin: 1rem auto 0px;
`
const Stack = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff !important;
  text-decoration: none !important;
  svg {
    fill: #fff;
    height: 2rem;
    margin: 0px 8px 0px 10px;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "assets/me.png" }) {
        childImageSharp {
          fixed(width: 100) {
            src
          }
        }
      }
    }
  `)
  const avatar = data.file.childImageSharp.fixed.src
  return (
    <FooterSection>
      <Container>
        <Avatar src={avatar} />
        <Author>Mark Dino Pelonia</Author>
        <ParaLine />
        <Quote>
          I code and design simple things, keep growing my skill one day at a
          time, and I love what I do.
        </Quote>
        <BadgeGroup>
          <BadgeLink delay={3} href='https://linkedin.com/in/markdinopelonia'>
            <Linkedin />
          </BadgeLink>
          <BadgeLink delay={4} href='https://facebook.com/markdino.pelonia'>
            <Facebook />
          </BadgeLink>
          <BadgeLink delay={5} href='https://github.com/markdino'>
            <GitHub />
          </BadgeLink>
          <BadgeLink delay={6} href='https://freecodecamp.org/markdino21'>
            <FreeCodeCamp />
          </BadgeLink>
          <BadgeLink delay={7} href='mailto:markdinopelonia447@gmail.com'>
            <Mail />
          </BadgeLink>
        </BadgeGroup>
        <CopyRightText>
          Code and design with <Heart /> by me <CopyRight />{' '}
          <sup>{new Date().getFullYear()}</sup>
        </CopyRightText>
        <Made>
          Made with
          <Stack href='https://www.gatsbyjs.com/'>
            <Gatsby /> Gatsby
          </Stack>
        </Made>
      </Container>
    </FooterSection>
  )
}

export default Footer
