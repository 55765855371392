import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import TestimonialsCarousel from './testimonialsCarousel'

import SectionHeader from './sectionHeader'

import testimonialsJson from '../data/testimonials.json'

const Main = styled.section.attrs({
  id: 'testimonials',
})`
  background-color: #f1f1f1;
  padding: 6.5rem 0px 5rem;
`
const Container = styled.div.attrs({
  className: 'container',
})``
const SubTitle = styled.p`
  text-align: center;
  font-size: 1.1rem;
  font-weight: 200;

  @media (min-width: 576px) {
    & {
      font-size: 1.2rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.3rem;
    }
  }
`
const Avatar = styled.div.attrs({
  className:
    'rounded-circle d-flex align-items-center overflow-hidden mx-auto my-5',
})`
  width: 80px;
  height: 80px;
`
const Message = styled.p`
  font-weight: 200;
  font-size: 1.4rem;
  max-width: 700px;
  margin: 0 auto;

  @media (min-width: 576px) {
    & {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.6rem;
    }
  }
`
const Name = styled.p`
  font-weight: 700;
  font-size: 1.3rem;
  margin: 3rem 0px 0px;
`
const Title = styled.p`
  font-weight: 300;
  margin: 0px;
`
const Website = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  font-size: 0.9rem;
  text-decoration: none !important;
  color: inherit !important;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`
const AOS_FADE_LEFT = styled.div.attrs({
  'data-aos': 'slide-left',
  'data-aos-delay': 100,
  'data-aos-duration': 1300,
  'data-aos-once': true,
})`
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`
const Testimony = ({
  name,
  title,
  message,
  website,
  websiteTrim,
  avatarSrc,
}) => (
  <main className='py-5 text-center'>
    <AOS_FADE_LEFT>
      <Avatar>
        <Image fixed={avatarSrc.childImageSharp.fixed} alt={name} />
      </Avatar>
      <Message>{`"${message}"`}</Message>
      <Name>{name}</Name>
      <Title>{title}</Title>
      {website && <Website href={website}>{websiteTrim}</Website>}
    </AOS_FADE_LEFT>
  </main>
)

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "testimonials" } }) {
        nodes {
          childImageSharp {
            fixed(width: 80) {
              ...GatsbyImageSharpFixed
            }
            id
          }
          base
        }
      }
    }
  `)
  const testimonials = testimonialsJson.map(testimony => {
    testimony.avatarSrc = data.allFile.nodes.find(
      avatar => avatar.base === testimony.avatar
    )
    if (testimony.website) {
      if (testimony.website.includes('https://')) {
        testimony.websiteTrim = testimony.website.replace('https://', '')
      } else if (testimony.website.includes('http://')) {
        testimony.websiteTrim = testimony.website.replace('http://', '')
      } else {
        testimony.websiteTrim = testimony.website
      }
    }
    return testimony
  })

  return (
    <Main>
      <Container>
        <SectionHeader className='mt-3'>Testimonials</SectionHeader>
        <SubTitle>
          People I've worked with have said some nice things...
        </SubTitle>
        <TestimonialsCarousel>
          {testimonials.map(testimony => (
            <Testimony key={testimony.name} {...testimony} />
          ))}
        </TestimonialsCarousel>
      </Container>
    </Main>
  )
}

export default Testimonials
