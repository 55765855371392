import React from 'react'
import styled from 'styled-components'

const Col = styled.section.attrs(({ delay }) => ({
  className: 'col col-lg-4 col-md-6 col-sm-9 col-12',
  'data-aos': 'zoom-in',
  'data-aos-delay': 100 * (delay + 1),
  'data-aos-once': true,
}))``
const Card = styled.figure`
  position: relative;
  width: 100%;
  max-width: 380px;
  height: 14rem;
  margin: 0 auto 2rem;
`
const Fill = styled.div.attrs({
  className: 'position-absolute w-100 h-100',
})`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 1rem;
`
const Thumbnail = styled.img`
  object-fit: cover;
`
const Content = styled.figcaption.attrs({
  className: 'p-4 d-flex flex-column justify-content-center',
})`
  color: #fff;
  text-align: center;
  background: rgba(13, 45, 76, 0.95);
  z-index: 1;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &:hover {
    opacity: 1;

    .badge-stack {
      width: 2rem;
      height: 2rem;
      opacity: 1;
    }
  }
`
const LinkGroup = styled.div.attrs({
  className: 'row m-0 justify-content-center w-100',
})``
const Link = styled.a.attrs(props => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  className: `btn btn-outline-${
    props.color ? props.color : 'warning'
  } badge-pill 
      mt-2 mx-auto px-3 text-capitalize ${props.enable ? '' : 'disabled'}`,
}))``

const Stack = styled.div.attrs({
  className: 'd-flex align-items-end',
})`
  z-index: -1;
`
const Badge = styled.div.attrs({
  className: 'badge-stack',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0rem;
  height: 0rem;
  margin: 0px 3px -1rem;
  border: solid 2px #fff;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: ${props => props.index / 10 + 0.6}s;

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`
const ProjectCard = ({
  name,
  desc,
  thumbnailSrc,
  webstatus,
  website,
  live,
  source,
  ArrayStack,
  delay,
}) => (
  <Col delay={delay}>
    <Card>
      <Fill
        as={Thumbnail}
        src={thumbnailSrc.childImageSharp.fixed.src}
        alt={name}
      />
      <Fill as={Content}>
        <h4>{desc}</h4>
        <LinkGroup>
          {website && (
            <Link href={website} enable={live}>
              {webstatus}
            </Link>
          )}
          {source && (
            <Link href={source} enable={true} color='secondary'>
              source code
            </Link>
          )}
        </LinkGroup>
        <Fill as={Stack}>
          {ArrayStack.map((tech, index) => (
            <Badge index={index} key={tech.childImageSharp.id}>
              <img
                src={tech.childImageSharp.fixed.src}
                alt={tech.name}
                title={tech.name}
              />
            </Badge>
          ))}
        </Fill>
      </Fill>
    </Card>
  </Col>
)

export default ProjectCard
