import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import ParaLine from './paraLine'

import Logo from '../svg/logo.svg'
import ChevronDown from '../svg/chevron-down.svg'
import bg from '../images/assets/bg.jpg'

const Main = styled.main`
  background-size: cover;
  background-position: center center;
  background-image: url(${bg});
`
const Wrapper = styled.section`
  width: 100%;
  min-height: 90vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
`
const Container = styled.div.attrs({
  className: 'container pt-5 pb-4',
})`
  text-align: center;
  color: #fff;
`
const MyLogo = styled.svg`
  width: 130px;
  fill: #fff;

  @media (min-width: 576px) {
    & {
      width: 150px;
    }
  }
  @media (min-width: 768px) {
    & {
      width: 180px;
    }
  }
`
const Intro = styled.p`
  font-weight: 700;
  font-size: 1rem;
  font-style: italic;
  margin: 15px 0px 0px;

  @media (min-width: 576px) {
    & {
      font-size: 1.2rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.5rem;
    }
  }
`

const Title = styled.h1`
  font-weight: 900;
  font-size: 2.5rem;
  margin: 0px;

  span {
    color: ${props => props.theme.color};
  }
  @media (min-width: 576px) {
    & {
      font-size: 3rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 3.5rem;
    }
  }
`
const Sub = styled.h2.attrs({
  className: 'text-capitalize mb-md-5 mb-sm-3 mb-2',
})`
  font-weight: 200;
  font-size: 0.8rem;
  word-spacing: 0.1rem;

  @media (min-width: 576px) {
    & {
      font-size: 1rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.3rem;
    }
  }
`
const RoundedButton = styled.a.attrs({
  className: 'btn btn-sm',
})`
  border-radius: 1.25rem;
  margin: 0.5rem;
  padding: 0.3rem 0.8rem;
  min-width: 168px;
`
const OutlineBtn = styled(RoundedButton).attrs({
  className: 'btn-outline-light',
})``
const DefaultBtn = styled(RoundedButton).attrs({
  className: 'btn-light',
})``

const Badge = styled.a.attrs({
  className:
    'd-flex justify-content-center align-items-center shadow-sm rounded-circle',
})`
  position: absolute;
  background: #fff;
  margin: -20px auto -20px;
  padding: 4px 0 0 0;
  left: 0;
  right: 0;
  width: 40px;
  height: 40px;
  color: #000;

  &:hover {
    color: ${props => props.theme.color};
  }
`
const Hero = () => {
  return (
    <Main id="hero">
      <Wrapper>
        <Container>
          <MyLogo as={Logo} />
          <Intro>Hi there! I'm</Intro>
          <Title>
            <span>Mark Dino</span> Pelonia
          </Title>
          <ParaLine color='white' />
          <Sub>Front-end Software Engineer</Sub>
          <DefaultBtn to='#contact' as={Link}>
            Let's work together
          </DefaultBtn>
          <OutlineBtn to='#projects' as={Link}>
            Check out my projects
          </OutlineBtn>
        </Container>
      </Wrapper>
      <Badge as={Link} to='#services'>
        <ChevronDown width='20' height='20' />
      </Badge>
    </Main>
  )
}

export default Hero
