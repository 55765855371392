import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Services from '../components/services'
import Skills from '../components/skills'
import Projects from '../components/projects'
import Contact from '../components/contact'
import Testimonials from '../components/testimonials'
import Community from '../components/community'
import About from '../components/about'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <SEO title='Front-end Developer' />
    <Hero />
    <Services />
    <Skills />
    <Projects />
    <Contact />
    <Testimonials />
    <Community />
    <About />
    <Footer />
  </Layout>
)

export default IndexPage
