import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import SectionHeader from './sectionHeader'

import responsive from '../svg/responsive.svg'
import seo from '../svg/web_search.svg'
import wireframe from '../svg/wireframing.svg'

const ServicesSection = styled.section.attrs({
  id: 'services',
})`
  padding-top: 5rem;
  background: var(--light);
`
const Sub = styled.p`
  text-align: center;
  color: #696969;
  font-size: 1.1rem;
`
const Container = styled.section.attrs({
  className: 'container',
})``
const Content = styled.main.attrs({
  className: 'pt-md-4 pt-sm-3 pt-2 px-3 pb-4',
})`
  margin: 0px auto;
  max-width: 1000px;
  text-align: center;
  color: var(--dark);
`
const Row = styled.div.attrs({
  className: 'row',
})``

const Col = styled.div.attrs(({ delay }) => ({
  className: 'col col-md-4 col-sm-6 col-12 p-3 my-3 mx-auto',
  'data-aos': 'fade-up',
  'data-aos-delay': 100 * (delay + 1),
  'data-aos-duration': 500,
  'data-aos-once': true,
}))``
const ServiceThumbnail = styled.svg`
  max-width: 500px;
  max-height: 200px;
  width: 100%;
  height: 100%;

  .color-stroke {
    stroke: ${props => props.theme.color};
  }
  .color-fill {
    fill: ${props => props.theme.color};
  }
`
const ServiceTitle = styled.h4`
  text-align: center;
  margin: 1rem 0;
  min-height: 4rem;
`
const ServiceDesc = styled.p`
  color: #404040;
  font-size: 1.1rem;
`
const Services = () => {
  const [servicesWidth, setServicesWidth] = useState(1546)
  useEffect(() => {
    if (document) {
      setServicesWidth(document.querySelector('#services').clientWidth)
    }
  }, [])
  return (
    <ServicesSection>
      <Container>
        <SectionHeader>
          What I <span>do</span>
        </SectionHeader>
        <Sub>I make idea into reality</Sub>
        <Content>
          <Row>
            <Col delay={0}>
              <ServiceThumbnail as={responsive} />
              <ServiceTitle>Responsive Website Design</ServiceTitle>
              <ServiceDesc>
                built for an optimal user experience that achieves your business
                goals.
              </ServiceDesc>
            </Col>
            <Col delay={servicesWidth >= 576 ? 1 : 0}>
              <ServiceThumbnail as={seo} />
              <ServiceTitle>Search Engine Optimization (SEO)</ServiceTitle>
              <ServiceDesc>
                Make your website fast, easy to find, and reach the widest
                audience possible.
              </ServiceDesc>
            </Col>
            <Col delay={servicesWidth >= 576 ? 2 : 0}>
              <ServiceThumbnail as={wireframe} />
              <ServiceTitle>Convert Mockup to a Website</ServiceTitle>
              <ServiceDesc>
                Turn your mockup design projects from Photoshop or Figma into a
                live website.
              </ServiceDesc>
            </Col>
          </Row>
        </Content>
      </Container>
    </ServicesSection>
  )
}

export default Services
