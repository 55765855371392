import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import SectionHeader from './sectionHeader'
import ProjectCard from './projectCard'
import OpenSourceCard from './openSourceCard'

import projectJson from '../data/projects.json'
import openSourceJson from '../data/openSource.json'

const ProjectSection = styled.section.attrs({
  id: 'projects',
})`
  padding: 6rem 0 3rem;
  background: #f1f1f1;
`
const Container = styled.div.attrs({
  className: 'container pt-2',
})``
const Collection = styled.main.attrs({
  className: 'row py-5 justify-content-center',
})``

const SubHeader = styled.h4`
  margin: 1.5rem 0 1rem;
  font-size: 1.3rem;
  font-weight: 700;

  @media (min-width: 576px) {
    & {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.8rem;
    }
  }
`

const Projects = () => {
  const data = useStaticQuery(graphql`
    query {
      skills: allFile(filter: { relativeDirectory: { eq: "skills" } }) {
        nodes {
          childImageSharp {
            fixed(height: 44) {
              src
            }
            id
          }
          name
        }
      }
      thumbnail: allFile(filter: { relativeDirectory: { eq: "projects" } }) {
        nodes {
          childImageSharp {
            fixed(width: 500, quality: 100) {
              src
            }
            id
          }
          base
        }
      }
      opensource: allFile(
        filter: { relativeDirectory: { eq: "open source" } }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
          base
        }
      }
    }
  `)
  const [projects, setProjects] = useState(null)
  const [opensource, setOpensource] = useState(null)
  const [projectWidth, setProjectWidth] = useState(1546)

  useEffect(() => {
    setProjects(projectJson)
    setOpensource(openSourceJson)
  }, [])

  useEffect(() => {
    if (document) {
      setProjectWidth(document.querySelector('#projects').clientWidth)
    }
  }, [])

  const projectData = projects
    ? projects.map(prj => {
        prj.ArrayStack = data.skills.nodes.filter(stack =>
          prj.stack.toLowerCase().includes(stack.name.toLowerCase())
        )
        prj.thumbnailSrc = data.thumbnail.nodes.find(
          img => prj.thumbnail.toLowerCase() === img.base.toLowerCase()
        )
        return prj
      })
    : []

  const openSourceData = opensource
    ? opensource.map(contribution => {
        contribution.thumbnailSrc = data.opensource.nodes.find(
          img => contribution.thumbnail.toLowerCase() === img.base.toLowerCase()
        )
        return contribution
      })
    : []

  return (
    <ProjectSection>
      <Container>
        <SectionHeader>
          My Recent <span>Projects</span>
        </SectionHeader>
        <Collection>
          {projectData.map((project, index) => (
            <ProjectCard
              {...project}
              key={index}
              delay={projectWidth >= 576 ? index : 0}
            />
          ))}
        </Collection>
        <SubHeader>Open Source Contribution</SubHeader>
        {openSourceData.map((contribution, index) => (
          <OpenSourceCard {...contribution} key={index} />
        ))}
      </Container>
    </ProjectSection>
  )
}

export default Projects
