import React from 'react'
import styled from 'styled-components'
import { Col } from 'reactstrap'
import SectionHeader from './sectionHeader'

import Messenger from '../svg/facebook-messenger.svg'
import Envelope from '../svg/envelope.svg'
import LinkedIn from '../svg/linkedin.svg'
import Github from '../svg/github.svg'
import Facebook from '../svg/facebook.svg'
import FreeCodeCamp from '../svg/free-code-camp.svg'

const ContactSection = styled.section.attrs({
  id: 'contact',
  className: 'border-top border-bottom',
})`
  padding: 6rem 0 5rem;
`
const Container = styled.section.attrs({
  className: 'container pt-2',
})``

const Row = styled.div.attrs({
  className: 'row mt-md-5 mt-sm-3 mt-2',
})``
const BtnGroup = styled.div.attrs({
  className: 'row px-2',
})``
const Button = styled.a.attrs({
  className: 'btn m-2 d-flex align-items-center',
})`
  width: 210px;
  color: #000;
  background-color: ${props => props.theme.color};
  border-color: ${props => props.theme.color};
  transition: all 0.15s ease-in-out;
  gap: 5px;
  @media (max-width: 425px) {
    width: 100%;
    justify-content: center;
  }

  &:hover {
    color: #fff;
    fill: #fff;
    box-shadow: 1px 5px 8px #888;
    transform: translateY(-5px);
  }
  &:active {
    box-shadow: none;
    transform: translateY(0px);
  }
`
const MessengerBtn = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
  className: 'btn m-2 d-flex align-items-center btn-outline-primary',
})`
  width: 210px;
  fill: var(--blue);
  transition: all 0.15s ease-in-out;
  gap: 5px;
  @media (max-width: 425px) {
    width: 100%;
    justify-content: center;
  }

  &:hover {
    fill: #fff;
    box-shadow: 1px 5px 8px #888;
    transform: translateY(-5px);
  }
  &:active {
    box-shadow: none;
    transform: translateY(0px);
  }
`
const Intro = styled.h4`
  font-size: 1.3rem;
  font-weight: 700;

  @media (min-width: 576px) {
    & {
      font-size: 1.5rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.8rem;
    }
  }
`
const Para = styled.p`
  font-size: 1.1rem;

  @media (min-width: 768px) {
    & {
      font-size: 1.2rem;
    }
  }
`
const SocialGroup = styled.div.attrs({
  className: 'mt-5 mb-2 float-md-right',
})``
const SocialTitle = styled.h5`
  font-size: 1rem;
  font-weight: 700;

  @media (min-width: 576px) {
    & {
      font-size: 1.2rem;
    }
  }
  @media (min-width: 768px) {
    & {
      font-size: 1.4rem;
    }
  }
`
const Social = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
  className: 'my-2 mr-3',
})`
  svg {
    height: 25px;
    fill: var(--gray);
    transition: all 0.2s ease-in-out;
  }
  svg:hover {
    transform: scale(1.2);
  }
  #linkedin {
    fill: #0273df;
  }
  #github {
    fill: var(--dark);
  }
  #fb {
    fill: #3b5998;
  }
  #fcc {
    fill: #006400;
  }
  @media (min-width: 576px) {
    & {
      svg {
        height: 30px;
      }
    }
  }
  @media (min-width: 768px) {
    & {
      svg {
        height: 35px;
      }
    }
  }
`
// const AOS_FADE_RIGHT = styled.div.attrs({
//   'data-aos': 'fade-right',
//   'data-aos-duration': 1000,
//   'data-aos-once': true,
// })``
// const AOS_ZOOM_IN = styled.div.attrs({
//   'data-aos': 'zoom-in',
//   'data-aos-delay': 300,
//   'data-aos-once': true,
//   'data-aos-duration': 1000,
// })``
const Contact = () => {
  return (
    <ContactSection>
      <Container>
        <SectionHeader>
          Get in <span>touch</span>
        </SectionHeader>
        <Row>
          <Col md='8' xs='12'>
            <Intro>Stay connected</Intro>
            <Para>
              Interested in working together? Send me an email or say hi on
              messenger if you would like to chat. I'd love to hear from you.
            </Para>
            <BtnGroup>
              <Button href='mailto:markdinopelonia447@gmail.com'>
                <Envelope height='25' width='25' />
                Send me an email
              </Button>
              <MessengerBtn href='https://m.me/markdino.pelonia'>
                <Messenger height='25' width='25' />
                Say hi on messenger
              </MessengerBtn>
            </BtnGroup>
          </Col>
          <Col md='4' xs='12'>
            <SocialGroup>
              <SocialTitle>I'm also in:</SocialTitle>
              <Social href='https://linkedin.com/in/markdinopelonia'>
                <LinkedIn id='linkedin' />
              </Social>
              <Social href='https://github.com/markdino'>
                <Github id='github' />
              </Social>
              <Social href='https://facebook.com/markdino.pelonia'>
                <Facebook id='fb' />
              </Social>
              <Social href='https://freecodecamp.org/markdino21'>
                <FreeCodeCamp id='fcc' />
              </Social>
            </SocialGroup>
          </Col>
        </Row>
      </Container>
    </ContactSection>
  )
}

export default Contact
