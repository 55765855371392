import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import styled from 'styled-components'

const ClassCarousel = styled.div`
  & {
    .dots {
      li {
        button {
          width: 17px;
          height: 17px;
          border-color: #d8d8d8;
        }
      }
      .react-multi-carousel-dot--active {
        button {
          background-color: ${props => props.theme.color};
        }
      }
    }
    @media (max-width: 768px) {
      .react-multiple-carousel__arrow {
        background: rgba(0,0,0,0.1);

        &--right {
          right: 0;
        }

        &--left {
          left: 0;
        }
      }
    }
  }
`

const TestimonialsCarousel = ({ children, ...attributes }) => {
  const responsive = {
    device: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  }

  return (
    <ClassCarousel
      as={Carousel}
      {...attributes}
      responsive={responsive}
      customTransition='transform 500ms ease-in-out'
      arrows={true}
      showDots={true}
      dotListClass='dots'
    >
      {children}
    </ClassCarousel>
  )
}

export default TestimonialsCarousel
